
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    link() {
      return require(`@/assets/icons/${this.$props.name}.svg`);
    },
  },
});
