import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9d0a49ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  href: "https://download.overwolf.com/install/Download?Name=Shopkeeper&ExtensionId=fhmbpcpmimbjjpdempkphoeailpkcdmmclfkbmdh&Channel=web_dl_btn",
  target: "__blank"
}
const _hoisted_2 = { class: "dwnld-btn" }
const _hoisted_3 = {
  key: 1,
  class: "dwnld-btn"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    ($props.isLink)
      ? (_openBlock(), _createElementBlock("a", _hoisted_1, [
          _createElementVNode("button", _hoisted_2, _toDisplayString($props.text), 1)
        ]))
      : (_openBlock(), _createElementBlock("button", _hoisted_3, _toDisplayString($props.text), 1))
  ]))
}