
import { defineComponent } from 'vue';
import Header from "@/components/Header.vue";
import DownloadContent from "@/components/DownloadContent.vue";
import StepContent from "@/components/StepContent.vue";
import Recomendation from "@/components/Recomendation.vue";
import Analysis from "@/components/Analysis.vue";
import Graph from "@/components/Graph.vue";
import Footer from "@/components/Footer.vue";
export default defineComponent({
  components: {Footer, Graph, Analysis, Recomendation, Header, DownloadContent, StepContent }
});
