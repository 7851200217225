import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d552b5f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_DownloadContent = _resolveComponent("DownloadContent")!
  const _component_StepContent = _resolveComponent("StepContent")!
  const _component_Recomendation = _resolveComponent("Recomendation")!
  const _component_Analysis = _resolveComponent("Analysis")!
  const _component_Graph = _resolveComponent("Graph")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header),
    _createVNode(_component_DownloadContent),
    _createVNode(_component_StepContent),
    _createVNode(_component_Recomendation),
    _createVNode(_component_Analysis),
    _createVNode(_component_Graph),
    _createVNode(_component_Footer)
  ]))
}