
export default {
  props: {
    text: String,
    isLink: {
      required: false,
      default: false,
    },
  },
};
