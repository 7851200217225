
export default {
  setup(){
    const navs =['Label 1',
        'Label 2',
        'Label 3',
        'Label 4'
  ]
    return {
      navs
    }
  }
}
