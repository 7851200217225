import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-463667d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header-container" }
const _hoisted_3 = {
  key: 0,
  class: "nav-list"
}
const _hoisted_4 = { class: "nav-list--item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SvgIcon, {
        name: "logo",
        class: "logo"
      }),
      false
        ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.navs, (item) => {
              return (_openBlock(), _createElementBlock("li", _hoisted_4, _toDisplayString(item), 1))
            }), 256))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}