<template>
  <router-view/>
</template>

<style lang="scss">
@import "scss/globals.scss";

body {
  //background-image: url("../src/assets/images/bg.png");
  background-size: cover;
  width: 100%;
  height: 100%;
  margin: 0;
}
</style>
